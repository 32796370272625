













import { Vue, Component } from 'vue-property-decorator';

import MainHeader from '@/shared/resources/components/MainHeader.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import RegulationsText from '@/app/components/RegulationsText.vue';
import Card from '@/shared/resources/components/cards/Card.vue';

@Component({
  components: {
    Card,
    RegulationsText,
    GridCol,
    GridRow,
    MainHeader,
  },
})
export default class RegulationsView extends Vue {
}
